<script setup>
import Modal from '@/components/Modal.vue';
import PlanningModal from './PlanningModal';
import { computed, ref, watch } from 'vue';
import { useAsyncState } from '@vueuse/core';
import { inject } from 'vue';
import { useAuthStore } from '../../../stores/auth';
import Card from '../simple/Card';
import Loader from '../../../components/Loader';
import moment from 'moment';
import { useModalStore } from '../../../stores/modal';
import StatCard from '../StatCard';
import SearchInput from '../../../components/SearchInput';
// import RadarChart from '@/components/charts/RadarChart';
// import PersonalFeedback from '../../../components/PersonalFeedback/PersonalFeedback';
import { useMainStore } from '../../../stores';
import CheckupFeedback from '../../../components/PersonalFeedback/CheckupFeedback';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  submissionId: {
    type: Number,
    default: null,
  },
  teamId: {
    type: Number,
    default: null,
  },
  userId: {
    type: Number,
    default: null,
  },
  activeId: {
    type: Number,
    default: null,
  },
});

const radarLabels = Object.freeze([
  'EB',
  'UIT',
  'HIN',
  'PHB',
  'IM',
  'ATT',
  'PRES',
  'ONG',
]);

const activeIndex = ref(0);

const axios = inject('axios');
const authStore = useAuthStore();
const store = useMainStore();
const submissions = ref([]);
const planningModal = ref(null);
const { locale } = useI18n();

/**
 * Fetch the current users submissions via axios
 * ? Replace this with Vue-Query at some point?
 */
const { state, isLoading, execute } = useAsyncState(() => {
  return axios
    .request({
      url: `/checkupsubmissions/getprivate/${
        props.userId || authStore.user.id
      }`,
      method: 'GET',
      params: {
        submissionid: props.userId
          ? null
          : props.submissionId || null,
        _locale: locale.value,
      },
    })
    .then((res) => res.data);
}, []);

//! Separate submissions array is needed to visually update notes privacy
watch(
  () => state.value,
  (value) => {
    submissions.value = value;
    // If there is an active id passed to the modal, set it
    activeIndex.value = Math.max(
      value.findIndex((item) => item.id === props.activeId),
      0
    );
  }
);

watch(
  () => locale.value,
  () => {
    execute();
  }
);

const modalStore = useModalStore();
const mainStore = useMainStore();

// Currently active submission
const activeSubmission = computed(() => {
  return [...submissions.value][activeIndex.value] || null;
});

// Available tabs to use in submission modal
const tabs = Object.freeze({
  VITAMIN: {
    label: 'Vitamin',
    component: null,
    visible: true,
  },
  PROFILE: {
    label: 'Profile',
    component: null,
    visible: computed(
      () =>
        !mainStore.setting('hideuserprofile') &&
        !activeSubmission.value?.checkupentry.checkup.isPulse
    ),
  },
  FEEDBACK: {
    label: 'Feedback',
    component: null,
    visible: activeSubmission.value?.feedback,
  },
});
const activeTab = ref(tabs.VITAMIN);

watch(
  () => activeSubmission.value,
  (value) => {
    console.log(value);
    if (value.checkupentry.checkup.isPulse) {
      activeTab.value = tabs.VITAMIN;
    }
  }
);

const vitaminIndex = ref(0);

const updateNote = async (note) => {
  let sub = activeSubmission.value;
  note.isShared = !note.isShared;
  await axios.put(`/checkupsubmissions/${sub.id}/note`, {
    note,
  });
};

const search = ref(null);
</script>

<template>
  <Modal ref="planningModal">
    <PlanningModal
      :submission="activeSubmission"
      :is-manager="
        userId && authStore.user.id !== activeSubmission.authorid
      "
      :override-close="true"
      :meeting-user="
        userId ? activeSubmission.authorid : authStore.user
      "
      @closeModal="planningModal.setOpen(false)"
    />
  </Modal>
  <template v-if="isLoading">
    <div class="modal-content p-5">
      <Loader is-loading />
    </div>
  </template>
  <template v-else>
    <Card color="green">
      <template #header>
        <span class="text-sm overline">{{
          $t('submissions.author', {
            name: `${activeSubmission.author.firstName} ${activeSubmission.author.lastName}`,
          })
        }}</span>
        <button
          type="button"
          class="d-block ms-auto btn-close"
          aria-label="Close"
          @click="modalStore.closeModal"
        />
      </template>
      <!--  Content header    -->
      <!-- <h4 class="mb-2">
        {{ $t()}}
        Submissions by {{ activeSubmission.author.firstName }}
        {{ activeSubmission.author.lastName }}
      </h4> -->
      <template v-if="submissions.length > 1">
        <select v-model="activeIndex">
          <option
            v-for="(submission, i) in submissions"
            :key="`sub-${i}`"
            :value="i"
          >
            {{
              submission.checkupentry.checkup.title === 'own_vitamin'
                ? $t('vitamins.own_vitamin')
                : submission.checkupentry.checkup.title
            }}
            -
            {{ moment(submission.updated_at).format('DD/MM/YYYY') }}
          </option>
        </select>
      </template>
      <span
        v-else
        class="d-block title overline"
        >{{
          activeSubmission.checkupentry.checkup.title ===
          'own_vitamin'
            ? $t('vitamins.own_vitamin')
            : activeSubmission.checkupentry.checkup.title
        }}</span
      >
      <div
        class="d-flex justify-content-between align-items-center my-4"
      >
        <div class="btn-group">
          <button
            v-for="tab in Object.values(tabs).filter(
              (tab) =>
                tab.visible?.value === true || tab.visible === true
            )"
            :key="`tab-${tab.label}`"
            type="button"
            class="btn btn-secondary btn-sm"
            :class="tab.label === activeTab.label ? 'active' : ''"
            @click="activeTab = tab"
          >
            {{ tab.label }}
          </button>
        </div>
        <!--    v-if="
            +activeSubmission.authorid !== +authStore?.user?.id ||
            authStore?.user?.member?.filter(
              (team) => team.visiblity && !team.issegment
            )?.length
          "    -->
        <button
          v-if="!mainStore.setting('hidefeedback')"
          class="btn btn-sm btn-primary"
          @click="planningModal.setOpen(true)"
        >
          {{
            $t(
              userId
                ? 'message.plan_meeting'
                : 'message.vraag_gesprek_aan'
            )
          }}
        </button>
      </div>
      <!--  Analytics     -->
      <div
        v-if="
          !mainStore.setting('hidewbeng') &&
          !activeSubmission?.checkupentry.checkup.isPulse
        "
        class="d-flex gap-4 mb-4"
      >
        <StatCard
          class="w-100"
          :data="[
            {
              value: activeSubmission.isprocessed
                ? activeSubmission.data.data.wb_norm
                : 0,
            },
          ]"
          :title="$t('message.WB')"
          color="green--light"
          :header-tooltip="
            store.vitaminColors.find((v) => v.keys.includes('WB'))
              ?.body
          "
          tooltip
        />
        <StatCard
          class="w-100"
          :data="[
            {
              value: activeSubmission.isprocessed
                ? activeSubmission.data.data.eng_norm
                : 0,
            },
          ]"
          :title="$t('message.ENG')"
          color="blue--light"
          tooltip
          :header-tooltip="
            store.vitaminColors.find((v) => v.keys.includes('ENG'))
              .body
          "
        />
      </div>
      <template v-if="activeTab.label === tabs.VITAMIN.label">
        <div
          class="d-flex justify-content-between align-items-center mb-4"
        >
          <SearchInput v-model="search" />
          <select v-model="vitaminIndex">
            <option
              v-for="(vitamin, i) in activeSubmission.submission"
              :key="`vitamin-${vitamin.key}`"
              :value="i"
            >
              {{ vitamin.title }}
              <template v-if="vitamin.comments"
                >({{ vitamin.comments }})</template
              >
            </option>
          </select>
        </div>
        <div>
          <ul
            v-for="factor in activeSubmission.submission[
              vitaminIndex
            ].factors.filter((f) =>
              f.questions.some((q) =>
                search
                  ? q.title
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  : true
              )
            )"
            :key="`factor-${factor.key}`"
          >
            <li
              :class="[
                `c-${activeSubmission.submission[vitaminIndex].color}`,
              ]"
            >
              <div class="d-flex gap-4">
                <div class="w-100">
                  <p>
                    {{ factor.title }}
                  </p>
                  <ul>
                    <li
                      v-for="question in factor.questions?.filter(
                        (q) =>
                          search
                            ? q.title.toLowerCase().includes(search)
                            : true
                      )"
                      :key="`question-${question.key}`"
                      class="mb-3"
                    >
                      <p class="mb-1 text-theme--800">
                        {{ question.title }}
                      </p>
                      <span class="fst-italic text-theme--800">
                        {{
                          $t(
                            `message.${question.value || 'no_answer'}`
                          )
                        }}
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  v-if="
                    factor.notes.length &&
                    factor.notes
                      ?.filter((note) =>
                        search
                          ? note.content
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          : true
                      )
                      ?.some((note) => note.content)
                  "
                  class="w-100"
                >
                  <p class="mb-1">Comments</p>
                  <div
                    v-for="note in factor.notes.filter(
                      (note) => note.content
                    )"
                    :key="`note-${note.index}`"
                    class="rounded bg-theme--200 p-2 text-theme--800"
                  >
                    <div
                      v-if="
                        +activeSubmission.authorid ===
                        +authStore?.user?.id
                      "
                      class="d-flex text-sm mb-3"
                      style="cursor: pointer"
                      @click="updateNote(note)"
                    >
                      <img
                        v-show="note.isShared"
                        src="@/assets/icons/icon-toggle-check-box.svg"
                        alt="checkbox"
                        class="me-2"
                        style="height: 12pt"
                      />
                      <img
                        v-show="!note.isShared"
                        src="@/assets/icons/icon-toggle-check-box_off.svg"
                        class="me-2"
                        alt="checkbox"
                        style="height: 12pt"
                      />
                      <span class="text-sentence">{{
                        $t(
                          'message.ik_wil_deze_notities_delen_en_bespreken_met_mijn_manager'
                        )
                      }}</span>
                    </div>
                    {{ note.content }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </template>
      <template v-if="activeTab.label === tabs.PROFILE.label">
        <Card
          v-if="!mainStore.setting('hideuserprofile')"
          color="cambridge-blue--light"
        >
          <template #header>
            <span class="overline text-sm">{{
              $t('dashboard.personal_report')
            }}</span>
          </template>
          <Suspense>
            <template #fallback>
              <div style="width: 100%; aspect-ratio: 3/4">
                <Loader is-loading />
              </div>
            </template>
            <CheckupFeedback
              :data-id="activeSubmission.authorid"
              :data="[
                {
                  title:
                    activeSubmission.checkupentry?.checkup?.title ===
                    'own_vitamin'
                      ? $t('vitamins.own_vitamin')
                      : activeSubmission.checkupentry?.checkup?.title,
                  id: activeSubmission.id,
                  data: radarLabels.map((label) => ({
                    key: label,
                    score: activeSubmission.isprocessed
                      ? activeSubmission.data.data[
                          `${label.toLowerCase()}_norm`
                        ]
                      : 0,
                  })),
                },
              ]"
              :isprocessed="activeSubmission.isprocessed"
              type="user"
              :cluster="activeSubmission.data.cluster"
            />
          </Suspense>
        </Card>
        <!--        <Card color="purple&#45;&#45;light">
          <template #header>
            <span class="text-sm overline">Zoomed in</span>
          </template>
          <suspense>
            <template #fallback>
              <Loader is-loading />
            </template>
            <RadarChart
              :dataset="[
                {
                  label: '',
                  data: [
                    ...radarLabels.map((label) =>
                      activeSubmission.isprocessed
                        ? activeSubmission.data.data[
                            `${label.toLowerCase()}_norm`
                          ]
                        : 0
                    ),
                  ],
                },
              ]"
              :labels="[...radarLabels]"
              :cluster="activeSubmission.data.cluster"
            />
          </suspense>
        </Card>
        <Card
          v-if="activeSubmission.isprocessed"
          class="mt-4"
          color="orange-yellow"
        >
          <template #header>
            <span class="text-sm overline">Feedback</span>
          </template>
          <Suspense>
            <template #fallback>
              <Loader is-loading />
            </template>
            <PersonalFeedback
              type="user"
              :ids="[activeSubmission.id]"
            />
          </Suspense>
        </Card>-->
      </template>
      <template v-if="activeTab.label === tabs.FEEDBACK.label">
        <div>Feedback</div>
      </template>
    </Card>
  </template>
</template>

<style scoped lang="scss">
.list-questions {
  list-style: circle;
}
</style>
